<template>
  <div class="PageNotFound">
    <content-wrapper-block>
      <div class="error">
        <div class="error-title">404</div>
        <div class="error-subtitle">вы свернули не туда</div>
      </div>
    </content-wrapper-block>
    <!-- <features-block /> -->
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
    <the-footer />
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import FeaturesBlock from "@/components/blocks/FeaturesBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";

export default {
  name: "NewsCatPageNotFoundlogBlock",
  components: {
    ContentWrapperBlock,
    FeaturesBlock,
    BestApartmentsBlock,
    CallbackBlock,
  },
};
</script>

<style lang="scss" scoped>
.error {
  text-align: center;
  font-family: Bebas Neue;

  @media screen and (min-width: $screen-mini) {
    padding: 100px 0;
  }

  &-title {
    font-size: 150px;
    font-weight: 600;
  }
}
</style>
