var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PageNotFound" },
    [
      _c("content-wrapper-block", [
        _c("div", { staticClass: "error" }, [
          _c("div", { staticClass: "error-title" }, [_vm._v("404")]),
          _c("div", { staticClass: "error-subtitle" }, [
            _vm._v("вы свернули не туда"),
          ]),
        ]),
      ]),
      _c("best-apartments-block"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
      _c("the-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }